.rewards-list-container {
	.header-container {
		padding-top: 50px;
		border-bottom: 2px solid #2d2a2a;
	}
	.heading2 {
		padding-top: 30px;
	}
	.rewards-category-div {
		max-width: 200px;
		float: right;
		width: 200px;
	}
	.activity-block {
		cursor: pointer;
	}
	.activity-block-hover:hover {
		background-color: rgb(216, 216, 211);
		color: #000;
	}
	.activity-block .cart-button {
		width: 85%;
		text-align: center;
	}

	.label {
		font-weight: 600;
		font-size: 25px;
		margin: 30px 0px;
		border-bottom: 2px solid #2d2a2a;
	}
	.activity-block {
		cursor: pointer;
	}
	.container-card-btn {
		text-align: center;
	}
	.activity-block-hover .container-card-btn button:hover {
		border-color: #007bff;
		color: #007bff;
	}
	.activity-block-disable {
		background-color: #f1efefe0;
	}
	h4 {
		text-align: center;
		font-size: medium;
		padding-top: 5px;
	}
	.rewards-list-body {
		margin-top: 50px;
	}
}
